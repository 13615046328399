import styled from "styled-components";

export const BooksList = styled.ul`
  list-style: none;
  text-align: center;
  padding: 0;

  a:hover {
    font-weight: bold;
  }
`;

export const Book = styled.li``;

export const ContentContainer = styled.div`
  p {
    text-align: justify;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: right;
  width: 100%;
  top: 10px;
`;

export const ChangeThemeButton = styled.button`
  position: absolute;
  top: 0px;
  right: 10px;

  padding: 2px 10px;

  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;

  border: 1px solid;

  &:hover {
    ${(props) =>
      props.theme === "light"
        ? "background-color: black; color: white;"
        : "background-color: white; color: black;"}
  }
`;
