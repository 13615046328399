import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../ThemeContext/ThemeContext";
import { PageContainer, PageHeading2 } from "../../globalStyles";
import {
  ContentContainer,
  BookContent,
  ChapterHeading,
  Verse,
  NavContainer,
  NavControls,
  NavFields,
  Nav,
  ButtonsContainer,
  SubmitButton,
  HomeButton,
  HighlighterButton,
  EraserButton,
  BottomNav,
  PrevChapterButton,
  NextChapterButton,
  ScrollToTopButton,
  NavToggleButton,
  ChangeThemeButton,
} from "./BookPageStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faEraser,
  faCircleChevronUp,
  faCircleChevronDown,
  faArrowUpWideShort,
  faHouse,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

function BookPage() {
  let navigate = useNavigate();
  const params = useParams();

  const [bookNumber, setbookNumber] = useState(1);
  const [fileName, setFileName] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(0);
  const [selectedVerse, setSelectedVerse] = useState(0);
  const [bookInput, setBookInput] = useState("");
  const [chapterInput, setChapterInput] = useState("");
  const [verseInput, setVerseInput] = useState("");
  const [highlighterOn, sethighlighterOn] = useState(false);
  const [eraserOn, seteraserOn] = useState(false);
  const [is_nav_open, setis_nav_open] = useState(false);
  const [chapterContent, setChapterContent] = useState(null);

  const is_mobile = window.innerWidth <= 768; // Adjust breakpoint as needed

  const { theme, toggleTheme } = useContext(ThemeContext);

  let books_list = [
    "01_Adiparvan.json",
    "02_Sabhaparvan.json",
    "03_Aranyakaparvan.json",
    "04_Virataparvan.json",
    "05_Udyogaparvan.json",
    "06_Bhismaparvan.json",
    "07_Dronaparvan.json",
    "08_Karnaparvan.json",
    "09_Salyaparvan.json",
    "10_Sauptikaparvan.json",
    "11_Striparvan.json",
    "12_Santiparvan.json",
    "13_Anusasanaparvan.json",
    "14_Asvamedhikaparvan.json",
    "15_Asramavasikaparvan.json",
    "16_Mausalaparvan.json",
    "17_Mahaprasthanikaparvan.json",
    "18_Svargarohanaparvan.json",
  ];

  useEffect(() => {
    // console.log(params.bookID);
    // console.log(params.chapterNum);

    const input_filename = params.bookID;
    const book_num = parseInt(input_filename.split("_")[0]);
    setbookNumber(book_num);
    setBookInput(book_num);
    console.log("Reading Book:", bookNumber);

    const chapterNumber = params.chapterNum;
    const filePath = `${process.env.PUBLIC_URL}/data/${input_filename}`;

    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch file");
        }
        return response.text();
      })
      .then((data) => {
        if (fileName !== input_filename) {
          setFileName(input_filename);
          let file_data = JSON.parse(data);
          setFileContent(file_data);
          setSelectedChapter(chapterNumber - 1);
          setChapterInput(chapterNumber);
          setVerseInput("1");
          setChapterContent(file_data.chapters[chapterNumber - 1]);
        } else {
          setSelectedChapter(chapterNumber - 1);
          setChapterInput(chapterNumber);
          setChapterContent(fileContent.chapters[chapterNumber - 1]);
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  }, [params]);

  function handleNavToggle(event) {
    console.log("Nav toggled");
    event.preventDefault();
    setis_nav_open(!is_nav_open);
    console.log(is_nav_open);
  }

  function handleSubmitNav(event) {
    console.log("Submit button pressed");
    event.preventDefault();
    // Check if the input is a valid number
    let bookInputNumber = parseInt(bookInput);
    let chapterNumber = parseInt(chapterInput);
    let verseNumber = parseInt(verseInput);

    if (isNaN(bookInputNumber) || bookInputNumber < 1) {
      bookInputNumber = 1;
      console.log("Invalid Book Number");
      setBookInput("1");
    }

    if (isNaN(chapterNumber) || chapterNumber < 1) {
      chapterNumber = 1;
      setChapterInput("1");
    }

    if (isNaN(verseNumber) || verseNumber < 1) {
      verseNumber = 1;
      setVerseInput("1");
    }

    if (bookInputNumber !== bookNumber) {
      if (bookInputNumber > books_list.length) {
        bookInputNumber = books_list.length;
        console.log("Book number too high");
      }

      setBookInput(bookInputNumber);
      setbookNumber(bookInputNumber);
      setFileName(books_list[bookInputNumber - 1]);

      setSelectedChapter(0); // Pass index in JSON. (Index = ChapterNum - 1)
      setVerseInput("1");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      console.log("Changing to Book", fileName);

      navigate(`/book/${books_list[bookInputNumber - 1]}/1`);
      window.location.reload();
    } else if (chapterNumber !== selectedChapter + 1) {
      console.log("Chapter Changed", chapterNumber, selectedChapter);
      eraseHighlights();
      if (chapterNumber <= fileContent.chapters.length) {
        setSelectedChapter(chapterNumber - 1); // Pass index in JSON. (Index = ChapterNum - 1)
        // clearBookContent();
        navigate(`/book/${fileName}/${chapterInput}`);
        return;
      } else {
        alert(
          `Invalid chapter number. This book has only ${fileContent.chapters.length} chapters.\nOpening Chapter 1`
        );
        setSelectedChapter(0); // Pass index in JSON. (Index = ChapterNum - 1)
        setVerseInput("1");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        navigate(`/book/${fileName}/${chapterInput}`);
      }
    } else if (verseInput !== selectedVerse + 1) {
      console.log("Chapter Unchanged. Scrolling to verse.");
      if (verseNumber <= fileContent.chapters[selectedChapter].verses.length) {
        setSelectedVerse(verseNumber - 1); // Pass index in JSON. (Index = verseNumber - 1)

        let verseId = "verse-" + selectedVerse.toString();

        let verse = document.getElementById(verseId);
        if (verse) {
          verse.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
            offsetTop: -80,
          });
        } else {
          alert("Verse not found!");
        }
      } else {
        alert(
          `Invalid verse number - ${verseNumber}.\nThis chapter has only ${fileContent.chapters[selectedChapter].verses.length} verses`
        );
      }
    } else {
      console.log("Empty Click");
    }
  }

  function handleHomeClick(event) {
    console.log("Home button clicked");
    event.preventDefault();
    navigate(`/`);
  }

  function handleHighlighterToggle(event) {
    console.log("Highlighter Toggle");
    event.preventDefault();
    sethighlighterOn(!highlighterOn);
  }

  function handleErase(event) {
    console.log("Erasing Highlights");
    event.preventDefault();
    eraseHighlights();
  }

  function eraseHighlights() {
    seteraserOn(true);
    setTimeout(() => {
      seteraserOn(false);
    }, 500); // Reset after 500ms

    const highlightedElements = document.querySelectorAll(
      "span.highlightedText"
    );
    highlightedElements.forEach((element) => {
      const parent = element.parentNode;
      parent.replaceChild(
        document.createTextNode(element.textContent),
        element
      );
    });

    sethighlighterOn(false);
  }

  function handleTextHighlight(event) {
    console.log("Text highlighted");
    event.preventDefault();
    if (highlighterOn) {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      let range = selection.getRangeAt(0);

      // Clone the range to manipulate it without affecting the selection directly
      let startContainer = range.startContainer;
      let endContainer = range.endContainer;
      let adjustedRange = document.createRange();
      adjustedRange.setStart(range.startContainer, range.startOffset);

      // Function to traverse nodes and find the appropriate end point
      const traverseAndAdjustRange = (node) => {
        if (node === endContainer) {
          adjustedRange.setEnd(node, range.endOffset);
          return true;
        }

        if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "BR") {
            adjustedRange.setEndBefore(node);
            return true;
          }

          for (let child of node.childNodes) {
            if (traverseAndAdjustRange(child)) {
              return true;
            }
          }
        } else if (node.nodeType === Node.TEXT_NODE) {
          adjustedRange.setEnd(node, node.textContent.length);
        }
        return false;
      };

      traverseAndAdjustRange(startContainer);

      const selectedText = adjustedRange.toString();
      if (selectedText.trim() === "") return;

      const newNode = document.createElement("span");
      newNode.setAttribute("class", "highlightedText");
      if (theme === "light") {
        newNode.style.backgroundColor = "#ffe13d"; // You can set any highlight color here
      } else {
        newNode.style.backgroundColor = "#ffe13d"; // You can set any highlight color here
        newNode.style.color = "black"; // You can set any highlight color here
      }

      newNode.appendChild(document.createTextNode(selectedText));
      adjustedRange.deleteContents();
      adjustedRange.insertNode(newNode);
    }
  }

  const goToPrevChapter = (event) => {
    event.preventDefault();
    eraseHighlights();
    navigate(`/book/${fileName}/${selectedChapter}`);
  };

  const goToNextChapter = (event) => {
    event.preventDefault();
    eraseHighlights();
    navigate(`/book/${fileName}/${selectedChapter + 2}`);
  };

  const [isScrollButtonVisible, setisScrollButtonVisible] = useState(false);

  useEffect(() => {
    // Function to check if user has scrolled enough to show the button
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        // Change 300 to whatever threshold you prefer
        setisScrollButtonVisible(true);
      } else {
        setisScrollButtonVisible(false);
      }
    };

    // Event listener for scroll
    window.addEventListener("scroll", toggleVisibility);

    // Clean up
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling, if supported
    });
  };

  const Spacer = ({ width }) => (
    <span style={{ display: "inline-block", width }}>&nbsp;</span>
  );

  return (
    <PageContainer>
      <NavContainer
        className={`NavContainer ${is_nav_open ? "navOpen" : "navClosed"}`}
        theme={theme}
      >
        {is_mobile ? (
          <>
            <NavToggleButton
              className={`navToggle ${is_nav_open ? "navOpen" : "navClosed"}`}
              onClick={handleNavToggle}
            >
              {is_nav_open ? (
                <FontAwesomeIcon icon={faCircleChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faCircleChevronDown} />
              )}
            </NavToggleButton>

            {is_nav_open ? (
              <NavControls className="NavControls" onSubmit={handleSubmitNav}>
                <NavFields className="NavFields">
                  <Nav className="BookNav">
                    <label>Book:</label>
                    <input
                      type="number"
                      value={bookInput}
                      placeholder={bookNumber.toString()}
                      onChange={(e) => setBookInput(e.target.value)}
                    />
                    <label>/18</label>
                  </Nav>

                  <Nav className="ChapterNav">
                    <label>Chapter:</label>
                    <input
                      type="number"
                      value={chapterInput}
                      placeholder={(selectedChapter + 1).toString()}
                      onChange={(e) => setChapterInput(e.target.value)}
                    />
                    <label>
                      /{fileContent ? fileContent.num_chapters : 225}
                    </label>
                  </Nav>

                  <Nav className="VerseNav">
                    <label>Verse:</label>
                    <input
                      type="number"
                      value={verseInput}
                      placeholder={(selectedVerse + 1).toString()}
                      onChange={(e) => setVerseInput(e.target.value)}
                    />
                    <label>
                      /
                      {fileContent
                        ? fileContent.chapters[selectedChapter]
                            .chapter_total_verses
                        : 205}
                    </label>
                  </Nav>
                </NavFields>
                <ButtonsContainer className="ButtonContainer">
                  <SubmitButton type="submit">Submit</SubmitButton>
                </ButtonsContainer>
                <ButtonsContainer className="ButtonContainer">
                  <HomeButton
                    is_mobile={is_mobile}
                    is_nav_open={is_nav_open}
                    theme={theme}
                    onClick={handleHomeClick}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faHouse} />
                  </HomeButton>
                  {/* <HighlighterButton
                    is_on={highlighterOn.toString()}
                    onClick={handleHighlighterToggle}
                  >
                    <FontAwesomeIcon icon={faHighlighter} />
                  </HighlighterButton>
                  <EraserButton
                    onClick={handleErase}
                    theme={theme}
                    className={eraserOn ? "flash" : ""}
                  >
                    <FontAwesomeIcon icon={faEraser} />
                  </EraserButton> */}
                  <ChangeThemeButton theme={theme} onClick={toggleTheme}>
                    <FontAwesomeIcon icon={faLightbulb} />
                  </ChangeThemeButton>
                </ButtonsContainer>
              </NavControls>
            ) : (
              <ButtonsContainer className="ButtonContainer">
                <HomeButton
                  is_mobile={is_mobile.toString()}
                  is_nav_open={is_nav_open}
                  onClick={handleHomeClick}
                  type="button"
                >
                  <FontAwesomeIcon icon={faHouse} />
                </HomeButton>
                {/* <HighlighterButton
                  is_on={highlighterOn.toString()}
                  theme={theme}
                  onClick={handleHighlighterToggle}
                >
                  <FontAwesomeIcon icon={faHighlighter} />
                </HighlighterButton>
                <EraserButton
                  onClick={handleErase}
                  theme={theme}
                  className={eraserOn ? "flash" : ""}
                >
                  <FontAwesomeIcon icon={faEraser} />
                </EraserButton> */}
                <ChangeThemeButton theme={theme} onClick={toggleTheme}>
                  <FontAwesomeIcon icon={faLightbulb} />
                </ChangeThemeButton>
              </ButtonsContainer>
            )}
          </>
        ) : (
          <>
            <NavControls className="NavControls" onSubmit={handleSubmitNav}>
              <HomeButton
                is_mobile={is_mobile.toString()}
                is_nav_open={is_nav_open.toString()}
                onClick={handleHomeClick}
                type="button"
              >
                <FontAwesomeIcon icon={faHouse} />
              </HomeButton>
              <NavFields className="NavFields">
                <Nav className="BookNav">
                  <label>Book:</label>
                  <input
                    type="number"
                    value={bookInput}
                    placeholder={bookNumber.toString()}
                    onChange={(e) => setBookInput(e.target.value)}
                  />
                  <label>/18</label>
                </Nav>

                <Nav className="ChapterNav">
                  <label>Chapter:</label>
                  <input
                    type="number"
                    value={chapterInput}
                    placeholder={(selectedChapter + 1).toString()}
                    onChange={(e) => setChapterInput(e.target.value)}
                  />
                  <label>/{fileContent ? fileContent.num_chapters : 225}</label>
                </Nav>

                <Nav className="VerseNav">
                  <label>Verse:</label>
                  <input
                    type="number"
                    value={verseInput}
                    placeholder={(selectedVerse + 1).toString()}
                    onChange={(e) => setVerseInput(e.target.value)}
                  />
                  <label>
                    /
                    {fileContent
                      ? fileContent.chapters[selectedChapter]
                          .chapter_total_verses
                      : 205}
                  </label>
                </Nav>
              </NavFields>
              <ButtonsContainer className="ButtonContainer">
                <SubmitButton type="submit">Submit</SubmitButton>
              </ButtonsContainer>
              <ButtonsContainer className="ButtonContainer">
                <HighlighterButton
                  is_on={highlighterOn.toString()}
                  onClick={handleHighlighterToggle}
                >
                  <FontAwesomeIcon icon={faHighlighter} />
                </HighlighterButton>
                <EraserButton
                  onClick={handleErase}
                  className={eraserOn ? "flash" : ""}
                >
                  <FontAwesomeIcon icon={faEraser} />
                </EraserButton>
                <ChangeThemeButton theme={theme} onClick={toggleTheme}>
                  <FontAwesomeIcon icon={faLightbulb} />
                </ChangeThemeButton>
              </ButtonsContainer>
            </NavControls>
          </>
        )}
      </NavContainer>

      <ContentContainer
        className={`ContentContainer ${is_nav_open ? "navOpen" : "navClosed"}`}
      >
        {chapterContent ? (
          <>
            <PageHeading2>{fileContent.book_name}</PageHeading2>

            <BookContent className="BookContent">
              <ChapterHeading
                className="chapter-start"
                is_mobile={is_mobile.toString()}
              >
                <h2>{chapterContent.chapter_name}</h2>
              </ChapterHeading>
              {chapterContent.verses.map((verse, index) => (
                <Verse
                  key={index}
                  id={"verse-" + index.toString()}
                  onMouseUp={handleTextHighlight}
                >
                  <p className="vNum">
                    {fileContent.book_number.toString() +
                      "-" +
                      (selectedChapter + 1).toString() +
                      "-" +
                      verse.verse_number}
                  </p>
                  <p className="sans">
                    {verse.verse_data.map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                  <p className="trans">
                    {verse.verse_translation.debroy_trans}
                  </p>
                </Verse>
              ))}
            </BookContent>
            <br />
            {isScrollButtonVisible && (
              <ScrollToTopButton
                theme={theme}
                className={`scroll-to-top-btn ${
                  theme === "light" ? "light" : "dark"
                }`}
                onClick={scrollToTop}
              >
                <FontAwesomeIcon icon={faArrowUpWideShort} />
              </ScrollToTopButton>
            )}
            <ChapterHeading
              className="chapter-end"
              is_mobile={is_mobile.toString()}
            >
              <h3>{chapterContent.chapter_name} ends.</h3>
            </ChapterHeading>
            <BottomNav className="BottomNav">
              {selectedChapter > 0 ? (
                <PrevChapterButton onClick={goToPrevChapter}>
                  <h3>
                    ⇜ {fileContent.chapters[selectedChapter - 1].chapter_name}
                  </h3>
                </PrevChapterButton>
              ) : (
                <PrevChapterButton>
                  <h3>⨀</h3>
                </PrevChapterButton>
              )}
              {selectedChapter < fileContent.chapters.length - 1 ? (
                <NextChapterButton onClick={goToNextChapter}>
                  <h3>
                    {fileContent.chapters[selectedChapter + 1].chapter_name} ⇝
                  </h3>
                </NextChapterButton>
              ) : (
                <NextChapterButton>
                  <h3>
                    <Spacer width="40px" />
                    ⨀
                    <Spacer width="40px" />
                  </h3>
                </NextChapterButton>
              )}
            </BottomNav>
          </>
        ) : (
          <PageHeading2>Loading file content...</PageHeading2>
        )}
      </ContentContainer>
    </PageContainer>
  );
}

export default BookPage;
