import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import "./fonts.css";

export const GlobalStyle = createGlobalStyle`  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 0;
    font-family: 'EB Garamond', serif;

    div {
      width: 100%;
    }

    button {
      border-radius: 5px;
      cursor: pointer;
    }

    transition: all 0.3s ease;
  }
  
  body.light {
      background: white;
      color: black;

      a {
        color: grey;
      }

      a:hover {
        color: black;
      }

      button {
        background-color: white;
        color: black;
        border-color: black;
      }  
  }

  body.dark {
    background: black;
    color: white;
    a {
      color: grey;
    }

    a:hover {
      color: white;
    }

    button {
      background-color: black;
      color: white;
      border-color: white;
    }

    input {
      background-color: #3e3e3e;
      color: white;
    }
  }
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  width: 90%;
  max-width: 768px;

  h1 {
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
`;

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: auto;
  width: 100%;
`;

export const PageBreak = styled.hr`
  width: 100%;
`;

export const PageHeading1 = styled.h1`
  width: 100%;
  font-size: xxx-large;
`;

export const PageHeading2 = styled.h1`
  width: 100%;
  font-size: xx-large;
  text-align: center;
`;
