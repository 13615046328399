import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GlobalStyle } from "./globalStyles";
import {
  BookPage,
  HomePage,
  NoPage,
  UpdatesPage,
  ChangelogPage,
} from "./pages";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <NoPage />,
    },
    {
      path: "book/:bookID/:chapterNum",
      element: <BookPage />,
    },
    {
      path: "updates",
      element: <UpdatesPage />,
    },
    {
      path: "changelog",
      element: <ChangelogPage />,
    },
  ]);

  return (
    <div className="App">
      <GlobalStyle />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
