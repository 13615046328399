import styled, { keyframes } from "styled-components";

const flashAnimation = keyframes`
  0% {
    background-color: rgba(0,0,0,0);
  }
  50% {
    background-color: pink;
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;

  margin-top: 110px;

  @media (max-width: 767px) {
    margin-top: ${(props) =>
      props.className === "ContentContainer navOpen" ? "150px" : "80px"};
  }

  transition: all 0.3s ease;
`;

export const BookContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }

  /* styles for screens between 768px and 1023px */
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 80%;
  }

  /* styles for screens larger than 1023px */
  @media (min-width: 1024px) {
    width: 80%;
  }
`;

export const ChapterHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ className }) =>
    className === "chapter-start" &&
    `
    margin-bottom: -28px;
  `}

  ::before {
    content: "꧁ ";
    position: relative;
    top: -0.61rem;
    ${({ is_mobile }) =>
      is_mobile === "true" &&
      `
      top: -0.2rem;
    `}
    right: 0.2rem;
    font-size: 1rem;
  }

  ::after {
    content: " ꧂";
    position: relative;
    top: -0.59rem;
    ${({ is_mobile }) =>
      is_mobile === "true" &&
      `
      top: -0.2rem;
    `}
    left: 0.2rem;
    font-size: 1rem;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;

  @media (max-width: 767px) {
    height: ${(props) =>
      props.className === "NavContainer navOpen" ? "120px" : "40px"};
  }

  position: fixed;
  top: 0;
  background-color: ${(props) => (props.theme === "light" ? "#fff" : "black")};
  padding: 10px;
  // border-bottom: 1px solid gray;
  margin-bottom: 10px;

  box-shadow: ${(props) =>
    props.theme === "light"
      ? "0 4px 4px -2px rgba(0, 0, 0, 0.1);"
      : "0 4px 4px -2px rgba(255, 255, 255, 0.5);"};

  z-index: 999;

  div.NavControls {
    padding: 10px;
  }

  transition: all 0.3s ease;
`;

export const NavControls = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  margin: 5px;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  margin: 0 10px;

  width: 30%;

  input {
    margin: 2px;
    width: 15px;

    @media (max-width: 767px) {
      width: 25%;
    }

    /* styles for screens between 768px and 1023px */
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 25%;
    }

    /* styles for screens larger than 1023px */
    @media (min-width: 1024px) {
      width: 25%;
    }
  }
`;

export const NavFields = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
    margin: 10px 0;
  }

  /* styles for screens between 768px and 1023px */
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 60%;
  }

  /* styles for screens larger than 1023px */
  @media (min-width: 1024px) {
    width: 60%;
  }
`;

export const ButtonsContainer = styled.div`
  width: auto;

  button {
    margin: 10px;
  }
`;

export const SubmitButton = styled.button`
  /* Button styles */
  padding: 2px 10px;
  border: none;
  background-color: white;
  color: black;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;

  &:hover {
    ${(props) =>
      props.theme === "light"
        ? "background-color: #f0f0f0; color: black;"
        : "background-color: white; color: black;"}
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #b4b4b4;
  }

  &:active {
    transform: translateY(1px);
  }

  /* Disabled styles */
  &:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
  }
`;

export const HighlighterButton = styled.button`
  /* Button styles */
  padding: 2px 10px;
  border: none;
  ${({ is_on }) =>
    is_on === "true"
      ? "background-color: #ffe13d !important; color: black !important;"
      : "background-color: white"};

  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;

  /* Hover effect */
  &:hover {
    background-color: #ffe13d;
    ${(props) => (props.theme === "light" ? "" : "color: black;")};
  }

  /* Active effect */
  &:active {
    transform: translateY(1px);
  }

  /* Disabled styles */
  &:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
  }
`;

export const HomeButton = styled.button`
  /* Button styles */
  padding: 2px 10px;
  border: none;
  // color:
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;

  /* Hover effect */
  &:hover {
    background-color: lightgrey;
    ${(props) => (props.theme === "light" ? "" : "color: black;")};
  }

  /* Focus effect */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #b4b4b4;
  }

  /* Active effect */
  &:active {
    transform: translateY(1px);
  }

  /* Disabled styles */
  &:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
  }

  /* Apply animation when clicked */
  &.flash {
    animation: ${flashAnimation} 0.5s ease;
  }
`;

export const EraserButton = styled.button`
  /* Button styles */
  padding: 2px 10px;
  border: none;
  background-color: ${({ is_on }) => (is_on === "true" ? "pink" : "white")};
  // color:
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;

  /* Hover effect */
  &:hover {
    background-color: coral;
    ${(props) => (props.theme === "light" ? "" : "color: black;")};
  }

  /* Active effect */
  &:active {
    transform: translateY(1px);
  }

  /* Disabled styles */
  &:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
  }

  /* Apply animation when clicked */
  &.flash {
    animation: ${flashAnimation} 0.5s ease;
  }
`;

export const Verse = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  border-bottom: 2px solid gray;

  font-size: 1.2rem;

  p.sans {
    font-family: "Tiro Devanagari Sanskrit", serif;
    font-weight: 400;
    font-style: normal;
    // font-size: 1.2rem;
    text-align: center;
    margin: 10px 0;
  }

  p.trans {
    font-size: 1.2rem;
    text-align: justify;
  }
`;

export const BottomNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    background: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: inherit;

    h3 {
      font-size: 1.1em;
      margin: 5px 0 5px 0px;
      // border-bottom: 1px solid black;
      padding: 3px 3px 0px 3px;

      &:hover {
        color: #3d3c3c;
        // border-bottom: 1px solid #3d3c3c;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  /* styles for screens between 768px and 1023px */
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 60%;
  }

  /* styles for screens larger than 1023px */
  @media (min-width: 1024px) {
    width: 60%;
  }
  padding-bottom: 10px;
  // border-bottom: 2px solid gray;
  margin: 10px;
`;

export const PrevChapterButton = styled.button``;

export const NextChapterButton = styled.button``;

export const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 65px;
  right: 20px;

  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;

  ${(props) =>
    props.theme === "light"
      ? "background-color: black !important; color: white !important;"
      : "background-color: white !important; color: black !important;"}

  &:hover {
    background-color: #545353;
  }
`;

export const NavToggleButton = styled.button`
  position: fixed;
  top: ${(props) =>
    props.className === "navToggle navOpen" ? "95px" : "10px"};
  right: 10px;
  background: none;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
  outline: none;
  font-size: large;

  transition: all 0.3s ease;
`;

export const ChangeThemeButton = styled.button`
  padding: 2px 10px;
  border: none;

  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;

  &:hover {
    ${(props) =>
      props.theme === "light"
        ? "background-color: black; color: white;"
        : "background-color: white; color: black;"}
  }

  &:active {
    transform: translateY(1px);
  }
`;
